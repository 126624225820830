/* green #00bfff */
circle#dot.signal-check {
	animation: signal-check-one 1s infinite;
}
path#signal1.signal-check {
	animation: signal-check-two 1s infinite;
}
path#signal2.signal-check {
	animation: signal-check-three 1s infinite;
}
path#signal3.signal-check {
	animation: signal-check-four 1s infinite;
}
@keyframes signal-check-one {
	0% {
		fill: #808080;
	}
	36% {
		fill: #00bfff;
	}
	73% {
		fill: #808080;
	}
}
@keyframes signal-check-two {
	6% {
		fill: #808080;
	}
	43% {
		fill: #00bfff;
	}
	79% {
		fill: #808080;
	}
}
@keyframes signal-check-three {
	12% {
		fill: #808080;
	}
	48% {
		fill: #00bfff;
	}
	84% {
		fill: #808080;
	}
}
@keyframes signal-check-four {
	17% {
		fill: #808080;
	}
	54% {
		fill: #00bfff;
	}
	90% {
		fill: #808080;
	}
}

circle#dot.signal-fail {
	animation: signal-fail-one 3s forwards;
}
path#signal1.signal-fail {
	animation: signal-fail-two 3s forwards;
}
path#signal2.signal-fail {
	animation: signal-fail-three 3s forwards;
}
path#signal3.signal-fail {
	animation: signal-fail-four 3s forwards;
}
@keyframes signal-fail-one {
	0% {
		fill: #808080;
	}
	25% {
		fill: #ff0000;
	}
	100% {
		fill: #ff0000;
	}
}
@keyframes signal-fail-two {
	20% {
		fill: #808080;
	}
	50% {
		fill: #ff0000;
	}
	100% {
		fill: #ff0000;
	}
}
@keyframes signal-fail-three {
	45% {
		fill: #808080;
	}
	75% {
		fill: #ff0000;
	}
	100% {
		fill: #ff0000;
	}
}
@keyframes signal-fail-four {
	70% {
		fill: #808080;
	}
	100% {
		fill: #ff0000;
	}
}

circle#dot.signal-pass {
	animation: signal-pass-one 3s forwards;
}
path#signal1.signal-pass {
	animation: signal-pass-two 3s forwards;
}
path#signal2.signal-pass {
	animation: signal-pass-three 3s forwards;
}
path#signal3.signal-pass {
	animation: signal-pass-four 3s forwards;
}
@keyframes signal-pass-one {
	0% {
		fill: #808080;
	}
	25% {
		fill: #00bfff;
	}
	100% {
		fill: #00bfff;
	}
}
@keyframes signal-pass-two {
	20% {
		fill: #808080;
	}
	50% {
		fill: #00bfff;
	}
	100% {
		fill: #00bfff;
	}
}
@keyframes signal-pass-three {
	45% {
		fill: #808080;
	}
	75% {
		fill: #00bfff;
	}
	100% {
		fill: #00bfff;
	}
}
@keyframes signal-pass-four {
	70% {
		fill: #808080;
	}
	100% {
		fill: #00bfff;
	}
}
